import axios from 'axios'
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url
  timeout: 30000, // 请求超时时间,
})

// 判断是否为移动端
function isMobile() {
  const userAgent = navigator.userAgent;
  return /iPhone|iPad|iPod|Android/i.test(userAgent);
}
request.interceptors.request.use(
  config => {
    config.headers['platform'] = isMobile() ? 'Mobile' : 'PC'
    config.headers['version'] = '021500'
    return config
  },
  (error) => Promise.reject(error)
)
request.interceptors.response.use(
  response => {
    const res = response.data
    if (res.type === "application/x-zip-compressed" ||
      res.type === "application/vnd.ms-excel" || res.type === 'application/json') {
      return response
    } else if (res.code != 20000) {
      // Message({
      //     message: res.msg || 'Error',
      //     type: 'error',
      //     duration: 5 * 1000
      //   })
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    // Message({
    //   message: error.msg,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default request
